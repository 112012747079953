.button-group{
    display: flex;
    justify-content: center;
}

.dropzone-custom {
    .dropzone-input {
        outline: none;
        transition: border 0.24s ease-in-out;
        min-height: auto;
        padding: 1.5rem 1.75rem;
        text-align: center;
        cursor: pointer;
        border: 1px dashed $primary;
        background-color: $primary-light;
        border-radius: $border-radius !important;
    }
    .dropzone-files {
        display: block;
        list-style-type: disc;
        -webkit-margin-before: 1em;
                margin-block-start: 1em;
        -webkit-margin-after: 1em;
                margin-block-end: 1em;
        -webkit-margin-start: 0px;
                margin-inline-start: 0px;
        -webkit-margin-end: 0px;
                margin-inline-end: 0px;
        -webkit-padding-start: 0px;
                padding-inline-start: 0px;
    }
}
.is-invalid {
    .dropzone {
        .dropzone-input {
            color: #ff0000;
            border-color: #ff0000;
        }
    }
}

.dataTable .sorting{
    position: relative;
    cursor: pointer;
}
table.dataTable > thead .sorting:after {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.5;
    width: 1rem;
    height: 1rem;
}

table.dataTable > thead .sorting.actived:after, table.dataTable > thead .sorting:hover:after{
    opacity: 1;
}
.table tr td {
    border-top: 1px solid #EAEAEA;
}
@media (min-width: 992px){
    .header-fixed.toolbar-fixed{
        .toolbar{
            top: 0;
        }
        .wrapper{
            padding-top: var(--kt-toolbar-height);
        }
    }
}
@media (max-width: 991px){
    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed{
        .toolbar{
            display: none;
        }
        .wrapper{
            padding-top: var(--kt-toolbar-height-tablet-and-mobile);
        }
    }
}
